import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createPath } from 'react-router-dom';

import CollectionFilter from './CollectionFilter';
import CollectionBadge from './CollectionBadge';
import Size from './ScreenSize';

import { oauth, makeCancelable } from '../utils';
import { withRouter } from './helpers';

const PAGE_SIZE = 24;

class CollectionList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      search_terms: {},
      page: 0,
      finished: false,
      loading: false,
      collections: [],
      elementTop: 0
    };

    this.active_searches = [];

    this.onSearchResult = this.onSearchResult.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.onUpdateQuery = this.onUpdateQuery.bind(this);
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.removeEventListener('scroll', this.handleScroll);
    }
    this.active_searches.forEach(x => x.cancel());
  }

  componentDidMount() {
    if (this.root) {
      this.setState({ elementTop: this.root.offsetTop });
    }
  }

  onUpdateQuery(queryLocation) {
    const { navigate } = this.props;
    navigate(createPath(queryLocation));
  }

  onSearchResult(page, collections, search_terms) {
    const finished = collections.length < PAGE_SIZE;

    this.setState({
      search_terms,
      page,
      finished,
      loading: false,
      collections: 0 === page ? collections : this.state.collections.concat(collections)
    });
    if (this.root) {
      this.root.addEventListener('scroll', this.handleScroll);
    }
  }

  onSearch(page, search_terms) {
    const data = Object.assign({
      'max-results': PAGE_SIZE,
      'start-index': page * PAGE_SIZE
    }, search_terms);
    const search = makeCancelable(oauth('GET', 'collection', data));
    this.active_searches.push(search);
    search.promise.then(
      ({ json }) => {
        this.onSearchResult(page, json.collections, search_terms);
      }
    );
  }

  handleScroll(e) {
    if ((this.root.scrollHeight - this.root.scrollTop) >= this.root.clientHeight) {
      this.root.removeEventListener('scroll', this.handleScroll);
      if (!this.state.finished) {
        this.onSearch(this.state.page + 1, this.state.search_terms);
      }
    }
  }

  renderCollection(collection, index, collections) {
    const { identity } = this.props;
    const last = index === collections.length - 1;
    const onClick = (identity.company_id === collection.supplier_id) ?
      e => { window.open(`/collection/${collection.form_number}`); } :
      e => { window.open(`/collection/${collection.order_id}`); };
    const smallClear =  index % 2 === 0 ? <Size.SmallOnly key={`clearsmall-${index}`} style={{ clear: 'both' }} /> : null;
    const style = 'Published' !== collection.status_name || Date.parse(collection.date_expiry) < Date.now() ? { cursor: 'pointer', opacity: 0.5 } : { cursor: 'pointer' };
    return [
      index % 2 === 0 ? <Size.SmallOnly key={`clearsmall-${index}`} style={{ clear: 'both' }} /> : null,
      index % 3 === 0 ? <Size.MediumOnly key={`clearmedium-${index}`} style={{ clear: 'both' }} /> : null,
      index % 4 === 0 ? <Size.LargeOnly key={`clearlarge-${index}`} style={{ clear: 'both' }} /> : null,
      <div key={collection.order_id} className={'small-6 medium-4 large-3 columns' + (last ? ' end' : '')} style={style} onClick={onClick}>
        <CollectionBadge collection={collection} showSupplierDetails={identity.company_id === collection.supplier_id} />
      </div>
    ];
  }

  renderLoading() {
    return (
      <div className="row small-12 columns collapse" style={{ textAlign: 'center' }}>
        <img src="/images/gears.gif" />
      </div>
    );
  }

  render() {
    const { company_id, style, pathname, baseQuery } = this.props;
    const { loading, elementTop } = this.state;

    const rootStyle = {
      padding: 0,
      height: `calc(100vh - ${elementTop}px)`,
      overflowY: 'auto',
      ...style
    };

    const queryParams = ['themes'];
    if (company_id) {
        queryParams.push('include_expired');
        queryParams.push('status_id');
    } else {
        queryParams.push('supplier_id');
    }

    const onChangeFilter = terms => {
      const query = Object.keys(terms).filter(t => queryParams.includes(t)).reduce((o, k) => ({ ...o, [k]: terms[k] }), {});
      if (baseQuery?.has('id')) {
        query.id = baseQuery.get('id');
      }
      if (baseQuery?.has('division_id')) {
        query.division_id = baseQuery.get('division_id');
      }

      let queryStr = "";
      for (let key in query) {
          if (queryStr != "") {
              queryStr += "&";
          }

          let value = query[key];
          if (Array.isArray(value)) {
            for (let y in value) {
              queryStr += "&" + key + "=" + encodeURIComponent(value[y]);
            }
          }else{
            queryStr += key + "=" + encodeURIComponent(value);
          }
      }
      const queryLocation = {
        pathname,
        search: queryStr
      };

      this.onUpdateQuery(queryLocation);
      this.onSearch(0, terms);
    };
    return (
      <div ref={ref => this.root = ref} className="row small-12 columns" style={rootStyle}>
        <CollectionFilter key="filter" company_id={company_id} onChangeFilter={onChangeFilter} />
        <div className="row">
          {this.state.collections.map((c, i, collections) => this.renderCollection(c, i, collections))}
        </div>
        {loading && this.renderLoading()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  identity: state.identity,
  pathname: ownProps.location.pathname,
  baseQuery: new URLSearchParams(ownProps.location.search),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CollectionList));
