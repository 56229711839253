import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getThemeDropdown, getStatusOptions, getTenantOptions } from '../selectors/dropdowns';

import { createSpinnerPopup } from '../actions/popup';
import { createAddCollection } from '../actions/collection';

import CollectionThemes from './CollectionThemes';
import CollectionSuppliers from './CollectionSuppliers';
import Select from './Select';
import { withRouter } from './helpers';

class CollectionFilter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selected_themes: props.selected_themes,
      status_id: props.status_id,
      supplier_id: props.supplier_id,
      tenant_id: props.tenant_id,
      include_expired: props.include_expired
    };

    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.onChangeStatus = this.onChangeStatus.bind(this);
    this.onAddCollectionTheme = this.onAddCollectionTheme.bind(this);
    this.onDeleteCollectionTheme = this.onDeleteCollectionTheme.bind(this);
    this.onChangeSupplier = this.onChangeSupplier.bind(this);
    this.onChangeTenant = this.onChangeTenant.bind(this);
  }

  componentDidMount() {
    this.onChangeFilter();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.tenant_id !== this.props.tenant_id) {
      this.setState({ tenant_id: nextProps.tenant_id });
    }
    if (nextProps.supplier_id !== this.props.supplier_id) {
      this.setState({ supplier_id: nextProps.supplier_id });
    }
    if (nextProps.selected_supplier_id !== this.props.selected_supplier_id) {
      this.setState({ supplier_id: nextProps.selected_supplier_id });
    }
  }

  onChangeFilter() {
    const filter = {};
    const themes = Object.values(this.state.selected_themes).filter(t => !!t);
    if (themes.length) {
      filter.themes = themes;
    }
    if ('ALL' !== this.state.status_id) {
      filter.status_id = this.state.status_id;
    }
    if (this.state.supplier_id) {
      filter.supplier_id = this.state.supplier_id;
    }
    if (this.state.tenant_id) {
      filter.tenant_id = this.state.tenant_id;
    }
    filter.include_expired = this.state.include_expired ? 1 : 0;
    this.props.onChangeFilter(filter);
  }

  onChangeStatus(status_id) {
    this.setState({ status_id }, this.onChangeFilter);
  }

  onAddCollectionTheme(theme_id) {
    this.setState({ selected_themes: Object.assign({}, this.state.selected_themes, { [theme_id]: theme_id }) }, this.onChangeFilter);
  }

  onDeleteCollectionTheme(theme_id) {
    this.setState({ selected_themes: Object.assign({}, this.state.selected_themes, { [theme_id]: undefined }) }, this.onChangeFilter);
  }

  onChangeSupplier(supplier_id) {
    this.setState({ supplier_id }, this.onChangeFilter);
  }

  onChangeTenant(tenant_id) {
    this.setState({ tenant_id }, this.onChangeFilter);
  }

  onChangeIncludeExpired(include_expired) {
    this.setState({ include_expired }, this.onChangeFilter);
  }

  render() {
    const { company_id, tenant_id, suppliers, identity } = this.props;
    const { supplier_id } = this.state;
    const hasCapability = capability => identity.capabilities.includes(capability);

    const statuses = [
      { key: 'ALL', value: 'All Collections' }
    ].concat(this.props.statuses);

    const tenants = [
      { key: '', value: 'Any Distributor' }
    ].concat(this.props.tenants);
    return (
      <div className="small-12 columns collapse" style={{ marginTop: '1rem' }}>
        {'SUPPLIER' === identity.company_type ?
          <div className="row collapse">
            <div className="small-12 medium-4 columns end" style={{ paddingRight: '0.5rem' }}>
              <Select withMarginBottom options={statuses} value={this.state.status_id} change={this.onChangeStatus} />
            </div>
            <div className="small-12 medium-4 columns end" style={{ paddingRight: '0.5rem' }}>
              <label style={{ lineHeight: '2rem', marginTop: '0.1rem' }}>
                <input type="checkbox" style={{ margin: 0 }} checked={this.state.include_expired} onChange={e => this.onChangeIncludeExpired(e.target.checked)} />
                &nbsp;Show Expired Collections Too
              </label>
            </div>
          {false && !tenant_id && tenants.length > 1 ?
            <div className="small-12 medium-4 columns end">
              <Select withMarginBottom options={tenants} value={this.state.tenant_id} change={this.onChangeTenant} placeholder="Any Distributor" />
            </div> : null}
          </div> : null}
        {!company_id && 'TENANT' === identity.company_type ?
          <div className="row collapse">
            <CollectionSuppliers className="small-12 columns" style={{ margin: '1rem 0' }} suppliers={suppliers} onSelectSupplier={this.onChangeSupplier} supplier_id={supplier_id} />
          </div> : null}
        <div className="row collapse">
          <CollectionThemes className="small-12 columns" themes={this.props.themes} selected_themes={this.state.selected_themes} onAddCollectionTheme={this.onAddCollectionTheme} onDeleteCollectionTheme={this.onDeleteCollectionTheme} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const searchParams = new URLSearchParams(ownProps.router.location.search);
  let query = {};
  for(var pair of searchParams.entries()) {
    if(query.hasOwnProperty(pair[0])) {
      query[pair[0]] = [query[pair[0]]];
      query[pair[0]].push(pair[1]);
    }else{
      query[pair[0]] = pair[1];
    }
  }
  const selected_themes = (query.themes ? (Array.isArray(query.themes) ? query.themes : [query.themes]) : []).reduce((o, t) => ({ ...o, [t]: t }), {});
  const selected_supplier_id = state.temp.supplier_id;
  return {
    identity: state.identity,
    themes: getThemeDropdown(state),
    selected_themes,
    statuses: getStatusOptions(state, { order_type: 'COLLECTION' }),
    suppliers: Object.values(state.entities.collection_vendors),
    tenants: getTenantOptions(state),
    tenant_id: state.entities.tenants[ownProps.company_id] ? ownProps.company_id : '',
    selected_supplier_id,
    status_id: query.status_id || 'ALL',
    include_expired: query.include_expired || false,
    supplier_id: ownProps.company_id || query.supplier_id
  };
};

const mapDispatchToProps = dispatch => ({
  onAddCollection: () => Promise.resolve(dispatch(createSpinnerPopup('Creating Collection...'))).then(
    () => dispatch(createAddCollection())
  )
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CollectionFilter));
